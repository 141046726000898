import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import { Link, graphql } from "gatsby";
import Card from "components/card";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "which"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/pcselection/laptop-vs-desktop.png"}) { ...eyecatchImg },
    ccpuhikaku: file(relativePath: { eq: "cover/cpu/cpu-hikaku.png"}) { ...eyecatchImg },
    celectricbill: file(relativePath: { eq: "cover/pcselection/electricbill.png"}) { ...eyecatchImg },
    typec_desktop: file(relativePath: { eq: "programming/typec_desktop.png"}) { ...normalImg },
    remotedesktop: file(relativePath: { eq: "which/remotedesktop.png"}) { ...normalImg },
    wattchecker_hq: file(relativePath: { eq: "electric/wattchecker_hq.png"}) { ...normalImgWithHQ },
    cextention: file(relativePath: { eq: "cover/pcselection/extention.png"}) { ...eyecatchImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`ノートかデスクトップかどちらを選択？`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="ノートかデスクトップか" mdxType="Image" />
    <p>{`ノートパソコンは携帯性が高く、デスクトップは価格の割に性能が高い。つまり`}<strong parentName="p"><em parentName="strong">{`定位置で使うならばデスクトップ、場所を選ばず使うならばノートが良い`}</em></strong>{`。`}</p>
    <p>{`端的に言ってしまえばそれだけの話なのだが、本当にそれだけだろうかと、`}<strong parentName="p"><em parentName="strong">{`細かい点まで気になり決め手に欠けている人に対して十分量のアドバイスを行うことを目的`}</em></strong>{`とする。それでは始めよう。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "ノートパソコン擁護派の意見",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E6%93%81%E8%AD%B7%E6%B4%BE%E3%81%AE%E6%84%8F%E8%A6%8B",
        "aria-label": "ノートパソコン擁護派の意見 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ノートパソコン擁護派の意見`}</h2>
    <p>{`まずはノートパソコンの携帯性以外のメリットに着目していく。`}</p>
    <h3 {...{
      "id": "ノートパソコンはデスクトップと同等の快適性を実現できる",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AF%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E3%81%A8%E5%90%8C%E7%AD%89%E3%81%AE%E5%BF%AB%E9%81%A9%E6%80%A7%E3%82%92%E5%AE%9F%E7%8F%BE%E3%81%A7%E3%81%8D%E3%82%8B",
        "aria-label": "ノートパソコンはデスクトップと同等の快適性を実現できる permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ノートパソコンはデスクトップと同等の快適性を実現できる`}</h3>
    <p>{`デスクトップはキーボードとモニターが分離されて楽な姿勢で作業できるという利点がある。これはもっともであるが、`}<strong parentName="p"><em parentName="strong">{`ノートパソコンでも外部モニターと接続し、ワイヤレスマウスとキーボードをノートと繋ぐことでデスクトップと同様の環境を容易に再現可能`}</em></strong>{`である。`}</p>
    <Image {...props} name="typec_desktop" caption="外部モニタを利用したデスクトップ環境の構築" mdxType="Image" />
    <p>{`ノートパソコンを閉じ、ケーブルを一本接続し、ワイヤレスマウスとキーボードをノートパソコンに接続すれば準備完了である。`}</p>
    <p>{`端子が足りなければハブを購入しておけば問題はなく、また写真では`}<strong parentName="p"><em parentName="strong">{`Type-Cケーブルでの映像出力と給電を同時に行っているので充電器も不要`}</em></strong>{`である。`}</p>
    <p>{`よって、追加費用は掛かるものの、ノートパソコンに追加でモニタ、マウス、キーボードさえ購入すればデスクトップ環境が出来上がり、ノートパソコンの窮屈な環境からは脱却できる。`}</p>
    <h3 {...{
      "id": "電気代が１日３時間の使用でデスクトップより年間1000円安い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E9%9B%BB%E6%B0%97%E4%BB%A3%E3%81%8C%EF%BC%91%E6%97%A5%EF%BC%93%E6%99%82%E9%96%93%E3%81%AE%E4%BD%BF%E7%94%A8%E3%81%A7%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E3%82%88%E3%82%8A%E5%B9%B4%E9%96%931000%E5%86%86%E5%AE%89%E3%81%84",
        "aria-label": "電気代が１日３時間の使用でデスクトップより年間1000円安い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`電気代が１日３時間の使用でデスクトップより年間1000円安い`}</h3>
    <p>{`自身のノートパソコンとデスクトップでネットサーフィンやYoutubeの動画を見ているときの平均消費電力をワットチェッカーという専用機器で測った時、ノートはおよそ15wh、デスクトップは24インチモニタの出力も含めて45whと、30whの違いが出た。`}</p>
    <Image {...props} name="wattchecker_hq" caption="ワットチェッカーでの計測の様子" mdxType="Image" />
    <p>{`1日3時間パソコンを使うとして、1年間の電気代はノートで600円、デスクトップで1600円程度となる。`}</p>
    <p>{`その差は1000円なので、5年使うと考えると、`}<strong parentName="p"><em parentName="strong">{`ノートパソコンはランニングコストを考えると5000円引きで考えても良いということになる`}</em></strong>{`。これはノートパソコン派にとっては吉報である。`}</p>
    <p>{`ちなみに電気代をより詳しく検証したい人は以下の記事を参考にしてみて欲しい。豊富な実測値があるので参考になるはずである。`}</p>
    <Card {...props} title="パソコンの電気代を徹底検証" description="ノート、デスクトップ、ゲーミングパソコンのおよその電気代を知ることができます。" image="celectricbill" path="/electricbill/" mdxType="Card" />
    <h3 {...{
      "id": "持ち込みしやすいので処分が楽",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%8C%81%E3%81%A1%E8%BE%BC%E3%81%BF%E3%81%97%E3%82%84%E3%81%99%E3%81%84%E3%81%AE%E3%81%A7%E5%87%A6%E5%88%86%E3%81%8C%E6%A5%BD",
        "aria-label": "持ち込みしやすいので処分が楽 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`持ち込みしやすいので処分が楽`}</h3>
    <p>{`今の時代メルカリがあるので、梱包・発送たのメル便を使えばデスクトップであろうとノートであろうと自宅にパソコンを回収に来てくれる。`}</p>
    <p>{`しかし、`}<strong parentName="p"><em parentName="strong">{`ソフマップやハードオフ、パソコン工房などに持ち込みで売りに行きたい場合、携帯性が高いノートの方が楽`}</em></strong>{`である。`}</p>
    <p>{`また、不要になって暫く放置しておく時もノートだと場所を取らない。デカいものは存在自体が邪魔である。`}</p>
    <h2 {...{
      "id": "デスクトップ擁護派の意見",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E6%93%81%E8%AD%B7%E6%B4%BE%E3%81%AE%E6%84%8F%E8%A6%8B",
        "aria-label": "デスクトップ擁護派の意見 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`デスクトップ擁護派の意見`}</h2>
    <p>{`デスクトップパソコンは`}<strong parentName="p"><em parentName="strong">{`価格の割にパフォーマンスが高い`}</em></strong>{`点が決定の主な材料となるが、他にもいくつかポイントがあるので説明していきたい。`}</p>
    <p>{`電気代がやや高いというのはあるが、全体的な価格メリットを考慮するとデスクトップは圧倒的である。`}</p>
    <h3 {...{
      "id": "コストパフォーマンスが高い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B3%E3%82%B9%E3%83%88%E3%83%91%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%B3%E3%82%B9%E3%81%8C%E9%AB%98%E3%81%84",
        "aria-label": "コストパフォーマンスが高い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`コストパフォーマンスが高い`}</h3>
    <p>{`省電力設計がなされているモバイルPCに比べ、デスクトップのPCはサクサク動くことが多い。筐体が大きく、冷却性能にも優れているため熱がこもりCPU性能が低下してファーという音がする事も少ない。`}</p>
    <p>{`パソコンの性能を測るテストをベンチマークテストと呼ぶが、`}<strong parentName="p"><em parentName="strong">{`デスクトップパソコンの廉価版Core 3 CPUが、ノートパソコンのハイエンドCore 7を上回る`}</em></strong>{`スコアを叩きだす。`}</p>
    <Card {...props} title="CPU性能比較表" description="400を超えるCPUから高機能フィルタにより世代間/ベンチマーク/TDP/コスパ等の絞りこみが行えます。" image="ccpuhikaku" path="/cpu/" mdxType="Card" />
    <p><strong parentName="p"><em parentName="strong">{`デスクトップパソコンで８万円する性能をノートに求めようとすると11万円ぐらいかかる`}</em></strong>{`というのがおおよその感覚である。`}</p>
    <h3 {...{
      "id": "拡張性が高い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%8B%A1%E5%BC%B5%E6%80%A7%E3%81%8C%E9%AB%98%E3%81%84",
        "aria-label": "拡張性が高い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`拡張性が高い`}</h3>
    <p>{`筐体の大きさにもよるが、拡張性が高く、メモリの増設やグラフィックボードの付け替え、ハードディスクの増設などが行えることがデスクトップの魅力である。`}</p>
    <p>{`キーボードが気に入らなければそこだけ買う事もできるが、ノートだとセットになっているためそういう訳にも行かない。`}</p>
    <p>{`高度なゲームをしたくなった時、デスクトップパソコンならばグラフィックボードを買い増して取り付ければ良い。`}<strong parentName="p"><em parentName="strong">{`最初から全てを揃えておかなくても必要になった時に対応できる`}</em></strong>{`事がデスクトップパソコンの魅力の一つである。`}</p>
    <p>{`ただし、パソコン本体のサイズや構造によってどこまで拡張できるかが決まってくるためそこは注意しなければならない。`}<strong parentName="p"><em parentName="strong">{`タワー型と呼ばれる大きめの筐体ならば基本何でも拡張が可能`}</em></strong>{`だ。`}</p>
    <Card {...props} title="パソコンの拡張性とは" description="拡張が可能なパーツ、困難なパーツ、筐体サイズによる違いなど、パソコン購入時に考えるべき拡張性を徹底解説。" image="cextention" path="/extention/" mdxType="Card" />
    <h3 {...{
      "id": "修理代が安く、手間がかからない",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E4%BF%AE%E7%90%86%E4%BB%A3%E3%81%8C%E5%AE%89%E3%81%8F%E3%80%81%E6%89%8B%E9%96%93%E3%81%8C%E3%81%8B%E3%81%8B%E3%82%89%E3%81%AA%E3%81%84",
        "aria-label": "修理代が安く、手間がかからない permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`修理代が安く、手間がかからない`}</h3>
    <p>{`パソコンが壊れたときにノートパソコンは全体を修理に出さないとならないのに対して、`}<strong parentName="p"><em parentName="strong">{`デスクトップはパーツだけ交換すればよいので圧倒的に楽`}</em></strong>{`である。`}</p>
    <p>{`ノートパソコンは小さい筐体にすべてが詰まっているため、全体交換という修理パターンにもなりやすく購入時の価格に近い高額が請求されることも多い。`}</p>
    <p>{`自然落下、コーヒーをこぼしてキーボードが壊れる、液晶にヒビが入るなど、精密機械はいくつも壊れるポイントがあるので、リスクを恐れるならばデスクトップを購入しておいた方が良いだろう。`}</p>
    <h3 {...{
      "id": "性能の限界値が高い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%80%A7%E8%83%BD%E3%81%AE%E9%99%90%E7%95%8C%E5%80%A4%E3%81%8C%E9%AB%98%E3%81%84",
        "aria-label": "性能の限界値が高い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`性能の限界値が高い`}</h3>
    <p>{`例えば4Kで144Hzという非常に美麗なグラフィック映像をノートパソコンで求めようとしても、ノートパソコンの能力でそこまでの性能を引き出すことは不可能である。`}</p>
    <p>{`低消費バッテリーのノートパソコンはデスクトップと比べて性能の限界値が低く、しかも限界値に近づけるほど50万円越えなど異常に高価格になってしまう。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`ゲームやクリエイティビティで超高パフォーマンスを追求しようとした場合はデスクトップ一択`}</em></strong>{`である。`}</p>
    <h2 {...{
      "id": "ノートとデスクトップのメリットまとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%8E%E3%83%BC%E3%83%88%E3%81%A8%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E3%81%AE%E3%83%A1%E3%83%AA%E3%83%83%E3%83%88%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "ノートとデスクトップのメリットまとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ノートとデスクトップのメリットまとめ`}</h2>
    <p>{`最後にそれぞれのメリットを箇条書きとしてまとめておく。まず何を優先すべきかを考え、両者を比較検討してみて欲しい。`}</p>
    <AccentBox title="ノートパソコンのメリット" mdxType="AccentBox">
  <li>快適性はデスクトップと同等・・・ただ外部モニタ等追加費用は発生する</li>
  <li>電気代が安い・・・１日５時間使用で年間約3000円お得</li>
  <li>処分が容易・・・サイズ的に処置に困らない</li>
    </AccentBox>
    <AccentBox title="デスクトップのメリット" mdxType="AccentBox">
  <li>コスパが高い・・・電源と排熱処理に優れフルパワーな処理が可能</li>
  <li>拡張性が高い・・・メモリ、グラボ等を必要に応じて増設</li>
  <li>修理費が安い・・・パーツ分離のメリット、壊れた箇所を替えれば良い</li>
  <li>性能の限界値が高い・・・最高のクリエイティビティやゲーム体験を求めるなら一択</li>
    </AccentBox>
    <p>{`ただ、それぞれ捨てがたいメリットを持っているため決め切れないことも多々あるかと思われる。そこで２台持ちも含め幾つか選択肢を提示してみる。`}</p>
    <h2 {...{
      "id": "予算があればデスクトップとノートの２台持ちもあり",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E4%BA%88%E7%AE%97%E3%81%8C%E3%81%82%E3%82%8C%E3%81%B0%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E3%81%A8%E3%83%8E%E3%83%BC%E3%83%88%E3%81%AE%EF%BC%92%E5%8F%B0%E6%8C%81%E3%81%A1%E3%82%82%E3%81%82%E3%82%8A",
        "aria-label": "予算があればデスクトップとノートの２台持ちもあり permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`予算があればデスクトップとノートの２台持ちもあり`}</h2>
    <Image {...props} name="remotedesktop" alt="リモートデスクトップ" mdxType="Image" />
    <p>{`デスクトップは能力の点でノートパソコンでは超えられない性能を発揮することができ、一方でノートパソコンには携帯性が備わる。では`}<strong parentName="p"><em parentName="strong">{`デスクトップのトップ性能で携帯性を持ち合わせることは可能か？その解がリモートデスクトップ`}</em></strong>{`である。`}</p>
    <p>{`リモートデスクトップは`}<strong parentName="p"><em parentName="strong">{`インターネット接続を介して手元にないパソコンを操作する機能`}</em></strong>{`であり、出先のノートパソコンから高性能なデスクトップ環境にアクセスすることができる。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`対応するにはノートパソコン、デスクトップともにWindows ProのOSを搭載している必要があり`}</em></strong>{`、windows Homeではリモートデスクトップを使うことはできないため、将来的にリモートを考えているような場合はWindows Proを選択することを忘れないようにしたい。`}</p>
    <p>{`私は常にこの機能を使って会社のパソコンにアクセスしており、厳密に手元にあるパソコンと同等に快適かと言われると回線次第では不安定な場合もあるが、`}<strong parentName="p"><em parentName="strong">{`手元にデスクトップパソコンがあると勘違いできる程度には快適`}</em></strong>{`である。`}</p>
    <h2 {...{
      "id": "まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`まとめ`}</h2>
    <p>{`上記でノートにするかデスクトップにするかを考える上での必要な材料はそろったと思う。当然だが`}<strong parentName="p"><em parentName="strong">{`万人に共通する正解などはなく、自分のライフスタイルと予算を考慮して考えてみて欲しい`}</em></strong>{`。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      